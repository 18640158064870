var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{attrs:{"loading":_vm.loading,"title":"各网点大厅实时情况"}},[_c('div',{staticStyle:{"width":"120px"},attrs:{"slot":"layoutBox-right"},slot:"layoutBox-right"},[_c('Select',{attrs:{"box-shadow":"","allow-clear":false,"custom":"","replace-fields":{
          lable: 'msg',
          value: 'code',
        },"options":_vm.list,"placeholder":"请选择记录类型"},model:{value:(_vm.queryParam.isFlowWarning),callback:function ($$v) {_vm.$set(_vm.queryParam, "isFlowWarning", $$v)},expression:"queryParam.isFlowWarning"}})],1),_c('div',{ref:"scrollbarY",staticClass:"scrollbar-y",staticStyle:{"height":"100%","margin-top":"8px","padding-bottom":"24px"},on:{"scroll":_vm.onScroll}},_vm._l((_vm.dataSource),function(e){return _c('div',{key:e.organizationCode,staticStyle:{"padding":"16px 0","display":"flex","align-items":"center","cursor":"pointer"},on:{"click":function($event){return _vm.$bus.$emit('CameraDialog', {
            videoType: 'live',
            cameraOptions: {
              url: ("/" + _vm.$businessISG + "/monitor/hall/video-view"),
              query: {
                cameraUUID: e.uuid,
              },
              cameraList: e.cameraList,
            },
          })}}},[_c('CameraImg',{attrs:{"uuid":e.uuid}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between","font-size":"16px","font-weight":"400"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"14px"}},[_c('img',{staticStyle:{"margin-right":"6px"},attrs:{"src":require("@/assets/screen/bj/icon_jigou.png"),"alt":"icon_jigou"}}),_c('span',[_vm._v(_vm._s(e.organizationText))])]),_vm._l((e.counters),function(item,index){return _c('div',{key:index,staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"margin-right":"6px"},attrs:{"src":require("@/assets/screen/bj/icon_people.png"),"alt":"icon_people"}}),_c('span',[_vm._v(_vm._s(item.text)+"：")]),_c('span',{style:({
                color: _vm.getVisualColor(e.count),
                fontWeight: 'bold',
                letterSpacing: '2px',
              })},[_vm._v(_vm._s(item.value))])])})],2)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }