<template>
  <LayoutBox :loading="loading" title="各网点大厅实时情况">
    <div slot="layoutBox-right" style="width: 120px">
      <Select
        v-model="queryParam.isFlowWarning"
        box-shadow
        :allow-clear="false"
        custom
        :replace-fields="{
          lable: 'msg',
          value: 'code',
        }"
        :options="list"
        placeholder="请选择记录类型"
      ></Select>
    </div>
    <div
      ref="scrollbarY"
      class="scrollbar-y"
      style="height: 100%; margin-top: 8px; padding-bottom: 24px"
      @scroll="onScroll"
    >
      <div
        v-for="e of dataSource"
        :key="e.organizationCode"
        style="
          padding: 16px 0;
          display: flex;
          align-items: center;
          cursor: pointer;
        "
        @click="
          $bus.$emit('CameraDialog', {
            videoType: 'live',
            cameraOptions: {
              url: `/${$businessISG}/monitor/hall/video-view`,
              query: {
                cameraUUID: e.uuid,
              },
              cameraList: e.cameraList,
            },
          })
        "
      >
        <CameraImg :uuid="e.uuid"/>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 400;
          "
        >
          <div style="display: flex; align-items: center; margin-bottom: 14px">
            <img
              src="@/assets/screen/bj/icon_jigou.png"
              style="margin-right: 6px"
              alt="icon_jigou"
            />
            <span>{{ e.organizationText }}</span>
          </div>
<!--          <div style="display: flex; align-items: center">
            <img
              src="@/assets/screen/bj/icon_people.png"
              style="margin-right: 6px"
              alt="icon_people"
            />
            <span>大厅人数：</span>
            <span
              :style="{
                color: getVisualColor(e.count),
                fontWeight: 'bold',
                letterSpacing: '2px',
              }"
            >{{ e.count }}</span
            >
          </div>-->
          <div style="display: flex; align-items: center" v-for="(item,index) in e.counters" :key="index">
            <img
              src="@/assets/screen/bj/icon_people.png"
              style="margin-right: 6px"
              alt="icon_people"
            />
            <span>{{ item.text }}：</span>
            <span
              :style="{
                color: getVisualColor(e.count),
                fontWeight: 'bold',
                letterSpacing: '2px',
              }"
            >{{ item.value }}</span
            >
          </div>

        </div>
      </div>
    </div>
  </LayoutBox>
</template>

<script>
import {mapState} from 'vuex';
import {debounce, toNumber} from '@/views/screen/config';

export default {
  //组件注册
  components: {
    LayoutBox: () => import('@/views/screen/components/LayoutBox.vue'),
    CameraImg: () => import('../components/CameraImg.vue'),
    Select: () => import('@/views/screen/components/Select.vue'),
  },
  //组件传值
  props: {
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      queryParam: {
        isFlowWarning: '02',
      },
      list: [
        {
          msg: '预警记录',
          code: '01',
        },
        {
          msg: '实时记录',
          code: '02',
        },
      ],
      rowId: 'organizationCode',
      loading: false,
      dataSource: [],
      ipagination: {
        pageNo: 1,
        maxResults: 10,
        pages: 0,
        visible: 7,
        total: 0,
      },
      timer: null
    };
  },
  //计算属性
  computed: {
    ...mapState({
      getVisualColor: (state) => {
        return (value) =>
          toNumber(value) >=
          toNumber(state.screenStore.analysisitem['yellowWarningNumber'])
            ? toNumber(value) >=
            state.screenStore.analysisitem['redWarningNumber']
              ? '#fe1329'
              : '#ffcc1b'
            : '#25e3ff';
      },
    }),
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'refresh',
    },
    queryParam: {
      deep: true,
      handler: 'getData',
    },
    // refreshKey: {
    //   handler: 'refresh',
    // },
  },
  //DOM访问
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.refresh()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  //保存方法
  methods: {
    refresh() {
      this.$nextTick(() => {
        this.$refs.scrollbarY.scrollTop = 0;
        this.ipagination.pageNo = 1;
        this.getData();
      });
    },
    async getData() {
      try {
        this.loading = true;
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/hall/livetime-stat`,
          {
            bankOutlets: '01',
            pageNo: this.ipagination.pageNo,
            maxResults: this.ipagination.maxResults,
            ...this.globalQuery,
            ...this.queryParam,
          }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        const result = data.data[0];
        if (this.ipagination.pageNo == 1) {
          this.dataSource = result.records;
        } else {
          this.onExcludeRow(result.records);
        }
        if (this.ipagination.pageNo != 1 && !result.records.length) {
          this.ipagination.pageNo -= 1;
        }
        this.ipagination.pages = result.pages;
        this.ipagination.total = result.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    onScroll: debounce(function (e) {
      // 获取距离顶部的距离
      const scrollTop = e.target.scrollTop;
      // 获取可视区的高度
      const windowHeight = e.target.clientHeight;
      // 获取滚动条的总高度
      const scrollHeight = e.target.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        this.ipagination.pageNo += 1;
        this.getData();
      }
    }, 250),
    onExcludeRow(records) {
      for (const e of records) {
        if (!this.dataSource.find((ee) => ee[this.rowId] == e[this.rowId]))
          this.dataSource.push(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
